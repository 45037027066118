import { Component, OnInit, HostListener } from '@angular/core';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { DatePipe } from '@angular/common';
import { Observable, first, forkJoin, tap } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Attended, BookAppointmentDTO, TypeOfBA } from 'src/app/models/BookAppointmentDTO';
import { AppointmentDTO } from 'src/app/models/AppointmentDTO';
import { UserDTO } from 'src/app/models/UserDTO';
import { AnyComponent } from '@fullcalendar/core/preact';
import { of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

interface AppointmentData {
  id: number;
  appointmentID: number;
  date: string;
  startTime: string;
  staffID: number;
  placeID: number;
  sumTime: number;
}

interface DateData {
  start: string;
  end: string;
  appointments: AppointmentData[];
}

@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.css']
})
export class AddAppointmentComponent {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }
  router:Router = inject(Router);
  weekDate: string;
  weekDates: string[] = [];

  PlaceDTO: any;
  UserDTO: any;
  appointmentIDs: number[] = [];
  appointments: any[] = [];

  salonId: any;
  placeID: any;
  allStaff: any[] = [];

  term: any;
  termDate: any;
  // dateData: { [key: string]: any[] } = {};
  dateData: { [key: string]: DateData  } = {};


  selectedTreatment: any;
  isTreatmentSelected: boolean = false;

  isUserSelected: boolean = false;
  selectedUsers: any[] = [];
  selectedUser: any;
  selectedClient: any;

  clients: any[] = [];
 
  isClientsListVisible = false;
  searchControl = new FormControl('');
  filteredClients$: Observable<any[]>;
 
  selectedI: number;
  clientName: any;


  scheduleData: any[];
  appointmentID: any;

  selectedIndex: number;
  isMobile: boolean = false;

  currentDate: Date = new Date();
  startOfWeek: Date = this.getStartOfWeek(this.currentDate);
  endOfWeek: Date = this.getEndOfWeek(this.currentDate);
  weekDays: string[] = this.generateWeekDays();
  mobileDays: string[] = this.generateMobileDays();

  
  constructor(private salonService: SalonService,
    private auth: AuthService,
     private datePipe: DatePipe,
     private location: Location,
     private route: ActivatedRoute,  
     private fb: FormBuilder) {
    
      this.searchControl = new FormControl('');
this.filteredClients$ = this.searchControl.valueChanges.pipe(
  startWith(''),
  map(value => this.filterClients(value))
);

    this.showWeek();
 }

 ngOnInit(): void {

  console.log(this.weekDates);

  this.checkScreenSize(); 
  window.addEventListener('resize', this.checkScreenSize.bind(this));


  this.currentDate = new Date();
  this.showWeek();
  
 
  this.auth.getUser().subscribe({
    next: (data) => {
      if (data) {
        this.UserDTO = data;
        console.log(this.UserDTO);
        if (this.UserDTO && this.UserDTO.placeID) {
          this.placeID = this.UserDTO.placeID;

    
          this.auth.getSalon(this.placeID).subscribe({
            next: (data) => {
              if (data) {
                this.PlaceDTO = data;
                console.log(this.PlaceDTO);

          this.salonService.getClients(this.placeID).subscribe(
      (data) => {
        this.clients = data;
        console.log(this.clients);

      },
      (error) => {
        console.error( error);
      }
    );
                

  this.salonService.getStaffByAppointment(this.selectedTreatment.id).subscribe(
        (data) => {
            this.allStaff = data;
            this.selectedUser = this.allStaff[0];
            this.isUserSelected = true;
            this.salonService.setSelectedUser(this.selectedUser);
            this.updateBookedAppointments();
            // console.log(this.selectedTreatment.id);
            // console.log(this.allStaff);
        },
        (error) => {
        
        }
    ); 



              } else {
                console.warn();
              }
            },
            error: (salonError) => {
              console.error( salonError);
            }
          });
        } else {
          console.warn();
        }
      } else {
        console.warn();
      }
    },
    error: (userError) => {
      console.error( userError);
    }
  });

 this.selectedTreatment = this.salonService.selectedTreatment;
 this.selectedUser = this.salonService.selectedUser;
 this.isTreatmentSelected = true;
 this.selectedClient = this.salonService.selectedClient;
 

 this.loadAppointmentsForWeek();

 this.filteredClients$ = this.searchControl.valueChanges.pipe(
  startWith(''),
  map(value => this.filterClients(value))
);

this.searchControl.valueChanges.subscribe(value => {
  this.isClientsListVisible = !!value;
});
}

filterClients(value: string): any[] {
  if (!value) {
    return [];
  }

  const filterValues = value.toLowerCase().split(' ').filter(Boolean);
  return this.clients
    .filter(client => client !== null)
    .filter(client => {
      const clientName = (client.firstName + ' ' + client.lastName).toLowerCase().replace(/[čćžšđ]/g, (match) => {
        switch (match) {
          case 'č': return 'c';
          case 'ć': return 'c';
          case 'ž': return 'z';
          case 'š': return 's';
          case 'đ': return 'd';
          default: return match;
        }
      });
      return filterValues.every(word => clientName.includes(word));
    });
}

selectClient(client: any): void {
  this.searchControl.setValue(client.firstName + ' ' + client.lastName);
  this.isClientsListVisible = false;
  this.salonService.setSelectedClient(client);
  this.selectedClient = client;
  console.log(this.selectedClient.id);
}

loadAppointmentsForWeek() {
  this.weekDates.forEach(date => {
    this.getBookedAppointments(date).subscribe(
      data => {
        if (data && data.appointments && Array.isArray(data.appointments)) {
          this.dateData[date] = data;
          const appointmentsForDate = data.appointments.map(appointment => ({ startTime: appointment.startTime, sumTime: appointment.sumTime }));
        } else {
          console.error("Invalid data format for date", date);
        }
      },
      error => {
        console.error("Error fetching appointments for", date, ":", error);
      }
    );
  });
}

getBookedAppointments(date: string): Observable<any> {
  const staffId = this.salonService.selectedUser?.id;
  if (!staffId) {
    console.error("Staff ID is not available");
    return new Observable<any>();
  }
  return this.salonService.getBookedAppointments(date, staffId).pipe(
    tap(data => {
      this.dateData[date] = data; 
    })
  );
}



transformWord(word: string): string {
  return word.toLowerCase().split(' ').map(word => { 
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ').replace(/_/g, ' ');
}


generateTimeSlots(startTime: string, endTime: string): string[] {
  const slots: string[] = [];
  let currentTime = startTime;

  while (currentTime < endTime) {
    slots.push(currentTime);
    currentTime = this.addMinutes(currentTime, 15);
  }

  return slots;
}

isDisabled(date: string, time: string): boolean {
  const appointmentsForDate = this.dateData[date];
  if (appointmentsForDate && appointmentsForDate.appointments && Array.isArray(appointmentsForDate.appointments)) {

    for (const appointment of appointmentsForDate.appointments) {
      if (typeof appointment.startTime === 'string' && typeof appointment.sumTime === 'number') {
        if (time >= appointment.startTime && time < this.addMinutes(appointment.startTime, appointment.sumTime)) {
          return true; 
        }
      } else {
      
      }
    }
  } else {
 
  }
  return false; 
}

addMinutes(time: string, minutes: number): string {
  const [hours, mins] = time.split(':').map(Number);
  const d = new Date();
  d.setHours(hours);
  d.setMinutes(mins + minutes);

  const newHours = ('0' + d.getHours()).slice(-2);
  const newMinutes = ('0' + d.getMinutes()).slice(-2);

  return `${newHours}:${newMinutes}`;
}



selectUser(staff: any) {
  console.log("Selected staff:", staff);
  this.salonService.setSelectedUser(staff);
  this.selectedUser = staff;
  this.isUserSelected = true;
  this.loadAppointmentsForWeek();
  this.updateBookedAppointments();
}

private updateBookedAppointments() {
  this.weekDates.forEach(date => {
    this.getBookedAppointments(date).subscribe(
      data => {
        if (data && data.appointments && Array.isArray(data.appointments)) {
          this.dateData[date] = data;
          const appointmentsForDate = data.appointments.map(appointment => ({ startTime: appointment.startTime, sumTime: appointment.sumTime }));
        } else {
          console.error("Invalid data format for date", date);
        }
      },
      error => {
        console.error("Error fetching appointments for", date, ":", error);
      }
    );
  });
}

selectTerm(term: string, date: string) {
  this.term = term;
  this.termDate = date;
  if (this.isTreatmentSelected) {
    this.selectedTreatment.term = term;
    this.selectedTreatment.date = date;
    console.log(this.selectedTreatment);
    console.log(date);
  } else {
    console.error("Nema tretmana");
  }
  
}


getTreatmentDetails(appointmentIDs: number[]): void {
  for (const id of appointmentIDs) {
    this.salonService.getAppointments(id).subscribe((appointment) => {
      this.appointments.push(appointment);
      console.log(appointment);
    });
  }
}


getAppointmentStaff(appointmentId: number): any[] {
  const appointment = this.appointments.find(app => app.id === appointmentId);
  return appointment ? appointment.userDTOs : [];
}

  showWeek(){
    const today = new Date();
    const firstDay = today.getDate() - today.getDay();
    const lastDay = firstDay + 6;

    const prviDan = new Date(today.getFullYear(), today.getMonth(), firstDay);
    const lastDayObj = new Date(today.getFullYear(), today.getMonth(), lastDay);


    const firstDayFormatted = this.datePipe.transform(prviDan, 'dd.MM.yyyy');
    const lastDayFormatted = this.datePipe.transform(lastDayObj, 'dd.MM.yyyy');

    this.weekDate = `${firstDayFormatted}-${lastDayFormatted}`;

    this.startOfWeek = this.getStartOfWeek(this.currentDate);
    this.endOfWeek = this.getEndOfWeek(this.currentDate);
    this.weekDays = this.generateWeekDays();
    this.weekDates = this.generateWeekDatesForApi(this.startOfWeek);
    
  }

  getMonthYearString(date: Date): string {
    const months = [
      'Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun',
      'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'
    ];
  
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    return `${months[monthIndex]} ${year}`;
  }
  
    private getStartOfWeek(date: Date): Date {
      const currentDate = new Date(date);
      const dayOfWeek = currentDate.getDay();
      const diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      return new Date(currentDate.setDate(diff));
    }
  
    private getEndOfWeek(date: Date): Date {
      const startOfWeek = this.getStartOfWeek(date);
      return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
    }
  
    generateWeekDays(): string[] {
      const days = ['Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota', 'Nedelja'];
      return days;
    }
  
     generateMobileDays(): string[] {
      const days = ['Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub', 'Ned'];
      return days;
    }
  
    nextWeek() {
      this.currentDate.setDate(this.currentDate.getDate() + 7);
      this.startOfWeek = this.getStartOfWeek(this.currentDate);
      this.endOfWeek = this.getEndOfWeek(this.currentDate);
      this.showWeek();
      this.loadAppointmentsForWeek();
    }
  
    prevWeek() {
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      this.startOfWeek = this.getStartOfWeek(this.currentDate);
      this.endOfWeek = this.getEndOfWeek(this.currentDate);
      this.showWeek();
      this.loadAppointmentsForWeek();
    }
  
    formatDate(date: Date): string {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }
    
    formatDateForDay(date: Date): string {
       return this.datePipe.transform(date, 'd');
    }
  
    formatDateForMonth(date: Date): string {
    
      return this.datePipe.transform(date, 'MMM');
   }
 
  
    getCircleDate(startOfWeek: Date, index: number): number {
      const clonedDate = new Date(startOfWeek);
      clonedDate.setDate(clonedDate.getDate() + index);
      return clonedDate.getDate();
    }
  
    getCircleDateMobile(startOfWeek: Date, index: number): number {
      const clonedDate = new Date(startOfWeek);
      clonedDate.setDate(clonedDate.getDate() + index);
      return clonedDate.getDate();
    }
  
    isPastDate(startOfWeek: Date, index: number): boolean {
      const currentDate = new Date();
      const targetDate = new Date(startOfWeek);
      targetDate.setDate(targetDate.getDate() + index);
  
      return targetDate < currentDate;
  }
  
  isCurrentDate(startOfWeek: Date, index: number): boolean {
    const currentDate = new Date();
    const targetDate = new Date(startOfWeek);
    targetDate.setDate(targetDate.getDate() + index);
  
    return targetDate.toDateString() === currentDate.toDateString();
  }

  generateWeekDatesForApi(startOfWeek: Date): string[] {
    const weekDates: string[] = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      weekDates.push(this.getFormattedDateForApi(currentDate)); 
    }
    return weekDates;
  
  }


  changeWeek(offset: number){
    const today = new Date();
    today.setDate(today.getDate() + (offset * 7));
    this.showWeek();
    console.log(this.weekDates);
  }

  getFormattedDateForApi(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  
  createAppointmentDTO(treatment: any): BookAppointmentDTO {
    const appointmentDTO: AppointmentDTO = {
      id: treatment.id,
      name: treatment.name,
      userDTOs: treatment.userDTOs,
      time: treatment.time,
      category: treatment.category,
      price: treatment.price,
      specialPrice: treatment.specialPrice,
      description: treatment.description,
      extraTime: treatment.extraTime,
      needAcception: treatment.needAccept,
    
    };
  
    const bookAppointmentDTO: BookAppointmentDTO = {
    
      appointmentID: appointmentDTO.id,
      userID: this.selectedClient.id,
      date: treatment.date, 
      time: treatment.term, 
      attended: Attended.NULL,
      typeOfBA: TypeOfBA.PRIHVACEN,
      canChange: true,
      staffID: this.selectedUser.id,
    };
  
    return bookAppointmentDTO;
  }
  

  bookAppointment(){
   
      if (this.selectedTreatment) { 
        const appointmentDTO = this.createAppointmentDTO(this.selectedTreatment); 
        console.log('Tretman koji je zakazan:', this.selectedTreatment);
        console.log('AppointmentDTO:', appointmentDTO);
  
        this.salonService.bookAppointment(appointmentDTO).subscribe({
          next: (result) => {
            console.log(result);
           //sta sad?
           this.router.navigate(['/partner/calendar']);
          },
          error: (error) => {
            console.error(error);
          }
        });
      } else {
        console.error("No treatment selected.");
      }

  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 500;
  }

  exit(){
    this.location.back();
  }

  goBack(){
    this.location.back();
  }

  goToBook(salonId:number){
    this.router.navigate(['schedule-a-appointment', salonId]);
  }

  navigateToAddTreatment(salonId:number){
    this.router.navigate(['add-treatment', salonId]);
    console.log(salonId)
  }
}
