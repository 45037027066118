import { Component, inject } from '@angular/core';
import { DayService } from 'src/app/services/partner-services/day.service';
import { HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalonService } from 'src/app/services/salon-services/salon.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormControl } from '@angular/forms';
import { Observable, forkJoin, tap } from 'rxjs';
import { BookAppointmentDTO } from 'src/app/models/BookAppointmentDTO';
import { ReturnBAWithWTDTO } from 'src/app/models/ReturnBAWithWTDTO';

@Component({
  selector: 'wc-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent {

  dateC = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());
  selectedDate: Date;

  allStaff: any;
  appointments: ReturnBAWithWTDTO[] = [];

  salonId: any;
  placeID: any;
  PlaceDTO: any;
  UserDTO: any;
  selectedStaff: any;
  selectedStaffMember: any;
  staffId: any[] = [];
  
  isUserSelected: boolean = true;
  selectedUsers: any[] = [];
  selectedUser: any;
  selectedUserMobile: any;
  selectedI: number;
  userName: any;

  showWrapper = false;
  staffPopupOpen = false;

  date: Date = new Date();
  day: any;
  months: { name: string, year: number }[] = [];
  selectedMonth!: { name: string; year: number; };
  selectedMonthIndex: number = 1;

   daysOfWeek = ['P', 'U', 'S', 'Č', 'P', 'S', 'N'];
  selectedMonthDays: { day: number, dayOfWeek: number }[] = [];

   srpskiMeseci = [
    'Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun',
    'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'
  ];
  
  router:Router = inject(Router);
  activeRoute:ActivatedRoute = inject(ActivatedRoute);

  openTreatment(appointmentID: number){
  this.router.navigate(['appointment-info', appointmentID], { relativeTo:this.activeRoute});
  }

  addAppointment(){
    this.router.navigate(['add-appointment']);
    }


  constructor(private dayService: DayService,
    private route: ActivatedRoute,
    private salonService: SalonService,
    private auth: AuthService
    ) {}

  toggleWrapper() {
    this.showWrapper = !this.showWrapper;
  }

  exitWrapper() {
    this.showWrapper = false;
  }


  ngOnInit() {

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

   for (let i = currentMonth - 1; i <= currentMonth + 5; i++) {
      const date = new Date(currentYear, i, 1);
      this.months.push({ name: this.srpskiMeseci[date.getMonth()], year: date.getFullYear() });
    }

    this.selectedMonth = this.months[this.selectedMonthIndex];

    this.auth.getUser().subscribe({
      next: (data) => {
        if (data) {
          this.UserDTO = data;
          if (this.UserDTO && this.UserDTO.placeID) {
            this.placeID = this.UserDTO.placeID;
            this.auth.getSalon(this.placeID).subscribe({
              next: (data) => {
                if (data) {
                  this.PlaceDTO = data;
          
                  this.salonService.getStaff(this.placeID).subscribe({
                    next: (staffData) => {
                      this.allStaff = staffData;
                      this.selectAllStaff();
                    },
                    error: (staffError) => {
                      console.error("nema zaposlenih", staffError);
                    }
                  });
    
                } else {
                  console.warn();
                }
              },
              error: (salonError) => {
                console.error( salonError);
              }
            });
          } else {
            console.warn();
          }
        } else {
          console.warn();
        }
      },
      error: (userError) => {
        console.error( userError);
      }
    });

    this.selectedDate = new Date();
    this.getBookedAppointments(this.getFormattedDateForApi(this.selectedDate)).subscribe(data => {
    
    });

  }

  openStaffPopUp(){
      this.staffPopupOpen = true;
  }

  closeStaffPopUp(){
    this.staffPopupOpen = false;
}

selectAllStaff() {
    this.selectedUser = this.allStaff;
    this.selectedUserMobile = this.allStaff[0];
    this.isUserSelected = true;
    this.staffPopupOpen = false;
    this.userName = ('Svi zaposleni');
  

  this.getBookedAppointments(this.getFormattedDateForApi(this.selectedDate)).subscribe();
  this.getBookedAppointmentsForStaff(this.getFormattedDateForApi(this.selectedDate)).subscribe();
}

  selectStaff(staff: any) {
      this.selectedUser = [staff];
      this.selectedUserMobile = [staff];
      // console.log(this.selectedUser.id)
      this.isUserSelected = true;
      this.staffPopupOpen = false;
      this.userName = this.selectedUser[0].firstName + ' ' + this.selectedUser[0].lastName;
    
 
    this.getBookedAppointments(this.getFormattedDateForApi(this.selectedDate)).subscribe();
    this.getBookedAppointmentsForStaff(this.getFormattedDateForApi(this.selectedDate)).subscribe();
  }

  getBookedAppointments(date: string): Observable<any> {
    let staffId;
    if (Array.isArray(this.selectedUser)) {
      staffId = this.selectedUser[0]?.id; 
    } else {
      staffId = this.selectedUser?.id; 
    }

    const formattedDate = this.getFormattedDateForApi(this.selectedDate);
    return this.salonService.getBookedAppointments(formattedDate, staffId).pipe(
      tap(data => {
        if (!data || (Array.isArray(data) && data.length === 0)) {
          this.day = []; 
          // console.log("Nema zakazanih termina za ovaj dan ili za odabranog korisnika.");
        } else {
          this.day = data;
          // console.log(data);
        }
      })
    );
  }

  getBookedAppointmentsForStaff(date: string): Observable<any> {
   
    if (Array.isArray(this.selectedUser)) {
      const staffIds = this.selectedUser.map(user => user.id);
      const observables = staffIds.map(id => this.salonService.getBookedAppointments(this.getFormattedDateForApi(this.selectedDate), id));
      return forkJoin(observables).pipe(
        tap(data => {
          this.appointments = data.flat();
          // console.log(`Za ID  imamo ovaj data:`, data);
        })
      );
    } else {
      return this.salonService.getBookedAppointments(this.getFormattedDateForApi(this.selectedDate), this.selectedUser?.id).pipe(
        tap(data => {
          this.appointments = data;
          // console.log(`Za ID  imamo ovaj data:`, this.appointments);
        })
      );
    }
  
  }


  formatTime(startTime: string, duration: number): string {
    let startHours: number;
    let startMinutes: number;
  
    if (typeof startTime === 'string') {
      const parts = startTime.split(':');
      startHours = parseInt(parts[0], 10);
      startMinutes = parseInt(parts[1], 10);
    } else if (typeof startTime === 'number') {
      startHours = Math.floor(startTime / 60);
      startMinutes = startTime % 60;
    } else {
      return '';
    }
  
    let endHours = startHours;
    let endMinutes = startMinutes + duration;
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes = endMinutes % 60;
    }
  
    return `${startHours}:${startMinutes < 10 ? '0' + startMinutes : startMinutes} - ${endHours}:${endMinutes < 10 ? '0' + endMinutes : endMinutes}`;
  }




  dateChanged(event: any) {
    this.selectedDate = event.value; 
  }

  sendDate() {
    const formattedDate = this.getFormattedDateForApi(this.selectedDate);
    // console.log("Selektovani datum:", formattedDate);
    this.getBookedAppointments(this.getFormattedDateForApi(this.selectedDate)).subscribe();
    this.getBookedAppointmentsForStaff(this.getFormattedDateForApi(this.selectedDate)).subscribe();
  }

  getFormattedDateForApi(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }
  
  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }




  transformWord(word: string): string {
    return word.toLowerCase().split(' ').map(word => { 
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ').replace(/_/g, ' ');
  }

  generateTimeSlots(startTime: string, endTime: string): string[] {
    const slots: string[] = [];
    let currentTime = startTime;
  
    while (currentTime < endTime) {
      slots.push(currentTime);
      currentTime = this.addFifteenMinutes(currentTime);
    }
  
    return slots;
  }

  addFifteenMinutes(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes + 15;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
  }


  navigate(step: number) {
    const nextIndex = this.selectedMonthIndex + step;

  if (nextIndex >= 0 && nextIndex < this.months.length) {
    this.selectedMonthIndex = nextIndex;
    this.selectedMonth = this.months[this.selectedMonthIndex];
  }
   
  const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

  this.generateMonthDays(currentMonth, currentYear);
  }

   generateMonthDays(month: number, year: number) {
    this.selectedMonthDays = [];

    
    const firstDayOfMonth = new Date(year, month, 1);
    const startingDayOfWeek = firstDayOfMonth.getDay();

 
    const numberOfDays = new Date(year, month + 1, 0).getDate();

    for (let i = 0; i < startingDayOfWeek; i++) {
     
      this.selectedMonthDays.push({ day: 0, dayOfWeek: i });
    }

    for (let i = 1; i <= numberOfDays; i++) {
      const currentDayOfWeek = (startingDayOfWeek + i - 1) % 7; 
      this.selectedMonthDays.push({ day: i, dayOfWeek: currentDayOfWeek });
    }
  }



  
}
