import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap, shareReplay, map, throwError, forkJoin  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogIn } from 'src/app/models/LogIn';
import { SignUp } from 'src/app/models/SignUp';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  

  private currentUser: any;
  private currentPlace: any;
  private appointmentID: any;
  private photoID: any;

  private baseUrl:string = "http://localhost:3001/user"
  constructor(private http: HttpClient, private router: Router) { }
  
  

  signup(signUpData: any){
      const url = `${this.baseUrl}/`;
      return this.http.post<any>("https://test.clicko.rs/api/auth/register", signUpData)
      .pipe(
        tap(res => this.setSignup(res)),
        shareReplay()
      );
  }

  private setSignup(result) {
    localStorage.setItem('id', result.id);
    localStorage.setItem('firstName', result.firstName);
    localStorage.setItem('lastName', result.lastName);
    localStorage.setItem('email', result.email);
    localStorage.setItem('telephone', result.telephone);
  }

  login(logInData: any) {
    return this.http.post<any>( "https://test.clicko.rs/api/auth/authenticate", logInData)
    .pipe(
      tap(res => this.setSession(res)),
      shareReplay()
    );
  }

  private setSession(authResult) {

    this.currentUser = {
      id: authResult.id,
      idToken: authResult.idToken,
      userRole: authResult.userRole
    };

    localStorage.setItem('id', authResult.id);
    localStorage.setItem('idToken', authResult.idToken);
    localStorage.setItem('userRole', authResult.userRole);
  }

 

  getCurrentUser(): any {
    return this.currentUser;
  } 

  getCurrentPlace(): any {
    return this.currentPlace;
  } 

  getAppointmentID(): any {
    return this.appointmentID;
  } 

  getPhotoID(): any {
    return this.photoID;
  }

  getUser(): Observable<any> {
    var id = localStorage.getItem('id')
    var idToken = localStorage.getItem('idToken')
    if (idToken) {
      const url = "https://test.clicko.rs/api/user";
      return this.http.get<any>(`${url}/${id}`)
      .pipe(
        tap(res => this.getPlaceid(res)),
        shareReplay()
      );
    } else {
     
      return null;
    }
  }

  private getPlaceid(result) {
    this.currentPlace = {
      id: result.placeID
     };
     
    localStorage.setItem('placeID', result.placeID);
  }
    
  

  deleteUser(): Observable<any> {
    if (this.currentUser && this.currentUser.id) {
      const url = `https://test.clicko.rs/api/user/${this.currentUser.id}`;
      return this.http.delete<any>(url);
    } else {
      return null;
    }
  }

  getPlace(): Observable<any> {
    var placeId = localStorage.getItem('placeID');
    console.log(placeId);
    if (placeId) {
      const url = "https://test.clicko.rs/api/place";
      return this.http.get<any>(`${url}/${placeId}`)
      .pipe(
        tap(res => this.getAppointmentIDs(res)),
        shareReplay()
      );
    } else {
      return null;
    }
  }

  getSalon(salonId: any): Observable<any> {
   const url = "https://test.clicko.rs/api/place";
    return this.http.get<any>(`${url}/${salonId}`)
      
  }

  private getAppointmentIDs(place): any[] {
    return place.appointmentsIDs.map(appointment => appointment.appointmentIDs);
  }

  getAppointment(appointmentIDs: any): Observable<any> {
    const url = "https://test.clicko.rs/api/appointment";
    return this.http.get<any>(`${url}/${appointmentIDs}`);
  }

  getPhoto(photoIDs: any): Observable<any> {
      const url = "https://test.clicko.rs/api/photo";
      return this.http.get<any>(`${url}/${photoIDs}`)
  }
    
  placeInfo(placeData: any): Observable<any>{
   
      const url = "https://test.clicko.rs/api/place/";
      return this.http.put<any>(url, placeData);
}

  
  clientInfo(clientData: any): Observable<any>{
      const userUrl = "https://test.clicko.rs/api/user/";
      return this.http.put<any>(userUrl, clientData);

}


  // getUsersAppointment(): Observable<any> {
  //   if (this.currentUser && this.currentUser.id) {
  //     const url = "https://test.clicko.rs/api/bookAppointment";
  //     return this.http.get<any>(`${url}/${this.currentUser.id}`);
  //   } else {
  //     return null; 
  //   }
  // }

  signout(){
    localStorage.removeItem('idToken');
    localStorage.removeItem('id');
    localStorage.removeItem('userRole');
    this.router.navigate(['login']);
  }

  storeToken(tokenValue: string){
    localStorage.setItem('idToken', tokenValue);
  }

  getToken(){
    return localStorage.getItem('idToken');
  }

  isLoggedIn(): boolean{
     return !!localStorage.getItem('idToken');
  }


}
