<div class="box">
   <div class="center">
    <form class="form-box"  [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
      <div class="form-information">
      <div class="input-group"> 
        <label class="label-text" for="name">Naziv Salona</label>
        <input class="input-text" type="text" id="name" formControlName="name">
      </div> 
      <div class="input-group"> 
        <label class="label-text" for="contact">Kontakt osoba</label>
        <input class="input-text" type="text" id="contact" formControlName="ownerName">
      </div>
      <div class="input-group">
        <label class="label-text" for="email">Email adresa</label>
        <input class="input-text" type="email" id="email" formControlName="ownerEmail">
      </div>
      <div class="input-group">
        <label class="label-text" for="adress">Adresa</label>
        <input class="input-text" type="text" id="adress" formControlName="adress">
      </div>
      <div class="input-group">
        <label class="label-text" for="telephone2">Broj telefona</label>
        <input class="input-text" type="text" id="telephone2" formControlName="telephone">
      </div>
      <div class="input-group">
        <label class="label-text" for="telephone2">Broj mobilnog</label>
        <input class="input-text" type="text" id="telephone2" formControlName="telephone">
      </div>
    </div>
    <div class="button-box"> 
      <button type="reset" class="discard-button">Odustani</button>
      <button type="submit" class="save-button">Sačuvaj</button> 
      </div>
    </form>
   </div>
    
</div>
