<div class="main">
    <div class="heading">
         <h3>{{PlaceDTO?.name}}</h3>
    </div>
    <div class="heading flex"> 
         <div class="moblie-buttons">
        <div (click)="openStaffPopUp()"> 
            <div *ngIf="staffPopupOpen" (click)="closeStaffPopUp()"
            class="overlay">
             </div>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405"><path d="M675.38-415.38q-32.76 0-56.38-23.62-23.62-23.62-23.62-56.38 0-32.77 23.62-56.39 23.62-23.61 56.38-23.61 32.77 0 56.39 23.61 23.61 23.62 23.61 56.39 0 32.76-23.61 56.38-23.62 23.62-56.39 23.62Zm-180 200v-36q0-18.62 9.3-33.76 9.29-15.14 26.4-21.78 33.79-14.23 69.93-21.35 36.14-7.11 74.37-7.11 36.68 0 72.88 7.11 36.2 7.12 71.43 21.35 17.1 6.64 26.4 21.78 9.29 15.14 9.29 33.76v36h-360ZM384.62-504.62q-49.5 0-84.75-35.25t-35.25-84.75q0-49.5 35.25-84.75t84.75-35.25q49.5 0 84.75 35.25t35.25 84.75q0 49.5-35.25 84.75t-84.75 35.25Zm0-120Zm-280 409.24v-65.85q0-25.95 14.3-47.71 14.31-21.75 38.93-32.14 53.07-26.92 110.23-40.61 57.16-13.69 116.54-13.69 24.23 0 48.46 2.53 24.23 2.54 48.46 6.7l-17.08 17.84q-8.54 8.93-17.08 17.85-15.69-3.08-31.38-4-15.69-.92-31.38-.92-54.16 0-106.97 11.69Q224.85-352 176.92-326q-13.07 7.31-22.69 18.23-9.61 10.92-9.61 26.54v25.85h240v40h-280Zm280-40Zm0-289.24q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Z"/></svg>
        </div>
        <div (click)="picker.open()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405"><path d="M224.62-120q-27.62 0-46.12-18.5Q160-157 160-184.62v-510.76q0-27.62 18.5-46.12Q197-760 224.62-760h70.76v-89.23h43.08V-760h286.16v-89.23h40V-760h70.76q27.62 0 46.12 18.5Q800-723 800-695.38v510.76q0 27.62-18.5 46.12Q763-120 735.38-120H224.62Zm0-40h510.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-350.76H200v350.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM200-575.39h560v-119.99q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v119.99Zm0 0V-720-575.39Zm280 181.54q-12.38 0-21.58-9.19-9.19-9.19-9.19-21.58 0-12.38 9.19-21.57 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.19 9.19 21.57 0 12.39-9.19 21.58-9.2 9.19-21.58 9.19Zm-160 0q-12.38 0-21.58-9.19-9.19-9.19-9.19-21.58 0-12.38 9.19-21.57 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.19 9.19 21.57 0 12.39-9.19 21.58-9.2 9.19-21.58 9.19Zm320 0q-12.38 0-21.58-9.19-9.19-9.19-9.19-21.58 0-12.38 9.19-21.57 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.19 9.19 21.57 0 12.39-9.19 21.58-9.2 9.19-21.58 9.19ZM480-240q-12.38 0-21.58-9.19-9.19-9.19-9.19-21.58 0-12.38 9.19-21.58 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.2 9.19 21.58 0 12.39-9.19 21.58Q492.38-240 480-240Zm-160 0q-12.38 0-21.58-9.19-9.19-9.19-9.19-21.58 0-12.38 9.19-21.58 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.2 9.19 21.58 0 12.39-9.19 21.58Q332.38-240 320-240Zm320 0q-12.38 0-21.58-9.19-9.19-9.19-9.19-21.58 0-12.38 9.19-21.58 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.2 9.19 21.58 0 12.39-9.19 21.58Q652.38-240 640-240Z"/></svg>
        </div>
        <div (click)="addAppointment()">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#1F3405"><path d="M460-460H240v-40h220v-220h40v220h220v40H500v220h-40v-220Z"/></svg>
        </div>
    </div>
        <div class="select-staff"> 
            <div class="select" id="" name="staffSelect"  (click)="openStaffPopUp()">
                 <p>{{ this.userName }}</p>
            </div> 
            <div class="staff-popup" *ngIf="staffPopupOpen">
                <div class="close-btn" (click)="closeStaffPopUp()">
                    <span class="material-symbols-outlined">
                      close
                    </span>
                </div>
                <div class="display" >
                   <div class="scroll">
                     <article (click)="selectAllStaff()" class="allStaff">
                        <div class="image">
                            <img class="img" src="../assets/icons/Ellipse 15.png" >
                       </div>
                       <p class="name">Svi zaposleni</p>
                    </article>
                    <ng-container *ngIf="allStaff && allStaff.length > 0">
                    <article *ngFor="let staff of allStaff " (click)="selectStaff(staff)">
                        <div class="image">
                             <img class="img" src="{{ staff.profilePhoto }}" *ngIf="staff.profilePhoto">
                             <p class="initial" *ngIf="!staff.profilePhoto"> {{ staff.firstName?.slice(0, 1) }}</p>
                        </div>
                        <div class="div-name">
                            <p class="name">{{ staff.firstName }} {{ staff.lastName }}</p>
                            <p class="position">{{ transformWord(staff.jobPosition) }}</p>
                        </div>
                    </article>
                   </ng-container>
                   </div>
                   
                </div>
            </div>
        </div>    
        <div class="date">
                <div class="day">
                    <mat-form-field class="example-full-width" appearance="fill" (click)="picker.open()">
                        <mat-label>Datum</mat-label>
                        <input matInput [matDatepicker]="picker"  [value]="selectedDate" (dateChange)="dateChanged($event)">
                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                          <mat-icon matDatepickerToggleIcon>
                            <span class="material-symbols-outlined">
                                calendar_month
                            </span>
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker>
                          <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Otkaži</button>
                            <button mat-raised-button  matDatepickerApply (click)="sendDate()">OK</button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                      </mat-form-field>    
                </div>
        </div>
        <div class="flex">
          <select class="select width hide" id="" name="" form="" >
            <option class="option" value="" selected value hidden>Dnevni pregled</option>
            <option class="option" value="">Dnevni</option>
            <option class="option" value="">Nedeljni</option>
            <option class="option" value="">Mesečni</option>
          </select>
          <button class="add-btn"  (click)="addAppointment()">
            <span class="material-symbols-outlined">
                add
            </span>
            <p>
               Dodaj termin
            </p>
        </button>
        </div>
    </div>
    
    <div class="page">
      <div class="desktopLayout">
        <div class="calendar">
         <div class="staffDiv">
                 <div>
                      <div class="background"></div>
                 </div>
                 <div class="staff" *ngFor="let staff of selectedUser">
                        <div class="image">
                           <img class="img" src="{{ staff.profilePhoto }}" *ngIf="staff.profilePhoto">
                            <p class="initial" *ngIf="!staff.profilePhoto"> {{ staff.firstName?.slice(0, 1) }}</p>
                        </div>
                        <p>{{ staff.firstName }} {{ staff.lastName }}</p>
                  </div>
         </div>
         <div class="calendarBody">
            <ng-container *ngIf="day; else nonWorkingDay">
                <ng-container *ngFor="let timeSlot of generateTimeSlots(day.start, day.end)">
                  <div class="calendarRow">
                    <div class="time-column">
                      <div class="time-cell">
                        <span class="time-label">{{timeSlot}}</span>
                      </div>
                    </div>
                   <div class="cellLayout">
                     <div class="cell" *ngFor="let staff of selectedUser">
                      <ng-container *ngIf="appointments && appointments.length > 0">
                        <div *ngFor="let appointment of appointments">
                          <ng-container *ngIf="appointment.appointments">
                            <div *ngFor="let appoint of appointment.appointments">
                              <div *ngIf="appoint.staffID === staff.id && appoint.startTime === timeSlot" 
                                 class="appointment" 
                                 (click)="openTreatment(appoint.id)"
                                 [ngClass]="{'canceled': appoint.attended === 'CANCELED' || appoint.attended === 'NOT_ARRIVED'}"
                                  [style.height]="4.2 * appoint.sumTime + 'px'">
                                <p class="time">{{ formatTime(appoint.startTime,appoint.sumTime)}}</p>
                                <h4>{{appoint.userName}}</h4>
                                <p>{{appoint.appointmentName}}</p>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                   </div>
                   
                </ng-container>
              </ng-container>
              <ng-template #nonWorkingDay>
                <tr>
                  <td colspan="2">Neradan dan</td>
                </tr>
              </ng-template>
         </div>
        </div>
      </div>
       <!-- <div class="desktop">
        <table>
            <thead>
                <tr>
                    <th class="staff">
                        <div class="background"></div>
                    </th>
                    <th class="staff" *ngFor="let staff of selectedUser">
                      <div class="image">
                        <img class="img" src="{{ staff.profilePhoto }}" *ngIf="staff.profilePhoto">
                        <p class="initial" *ngIf="!staff.profilePhoto"> {{ staff.firstName?.slice(0, 1) }}</p>
                   </div>
                        <p>{{ staff.firstName }} {{ staff.lastName }}</p>
                   </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="day; else nonWorkingDay">
                    <ng-container *ngFor="let timeSlot of generateTimeSlots(day.start, day.end)">
                      <tr>
                        <td class="time-column">
                          <div class="time-cell">
                            <span class="time-label">{{timeSlot}}</span>
                          </div>
                        </td>
                        <td *ngFor="let staff of selectedUser">
                          <ng-container *ngIf="appointments && appointments.length > 0">
                            <div *ngFor="let appointment of appointments">
                              <ng-container *ngIf="appointment.appointments">
                                <div *ngFor="let appoint of appointment.appointments">
                                  <div *ngIf="appoint.staffID === staff.id && appoint.startTime === timeSlot" 
                                     class="appointment" 
                                     (click)="openTreatment(appoint.id)"
                                     [ngClass]="{'canceled': appoint.attended === 'CANCELED' || appoint.attended === 'NOT_ARRIVED'}"
                                      [style.height]="4.2 * appoint.sumTime + 'px'">
                                    <p class="time">{{ formatTime(appoint.startTime,appoint.sumTime)}}</p>
                                    <h4>{{appoint.userName}}</h4>
                                    <p>{{appoint.appointmentName}}</p>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-template #nonWorkingDay>
                    <tr>
                      <td colspan="2">Neradan dan</td>
                    </tr>
                  </ng-template>
              </tbody>
        </table>
       
    </div> -->

    <div class="mobile">
        <p class="mobile-date"> {{ this.selectedDate | date: 'EEE, MMM d' | titlecase }}</p>
        <table>
            <tbody>
                <ng-container *ngIf="day; else nonWorkingDay">
                    <ng-container *ngFor="let timeSlot of generateTimeSlots(day.start, day.end)">
                      <tr>
                        <td class="time-column">
                          <div class="time-cell">
                            <span class="time-label">{{timeSlot}}</span>
                          </div>
                        </td>
                        <td *ngFor="let staff of selectedUser">
                          <ng-container *ngIf="appointments && appointments.length > 0">
                            <div *ngFor="let appointment of appointments">
                              <ng-container *ngIf="appointment.appointments">
                                <div *ngFor="let appoint of appointment.appointments">
                                  <div *ngIf="appoint.staffID === staff.id && appoint.startTime === timeSlot" class="appointment" (click)="openTreatment(appoint.id)" [style.height]="4.2 * appoint.sumTime + 'px'">
                                    <p class="time" >{{ formatTime(appoint.startTime,appoint.sumTime)}}</p>
                                    <h4 >{{appoint.userName}}</h4>
                                    <p >{{appoint.appointmentName}}</p>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-template #nonWorkingDay>
                    <tr>
                      <td colspan="2">Neradan dan</td>
                    </tr>
                  </ng-template>
     

            </tbody>
        </table>
    </div>
 </div>
    <router-outlet></router-outlet>
</div>


    
    
    